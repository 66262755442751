import { inject } from '@angular/core';

import { AvailableOrganizationSettingsService } from '@mp/kernel/settings/organization/data-access';

/**
 * A function that checks for any enabled organization setting.
 *
 * The function must be used within an injection context.
 */
export function hasAnyEnabledOrganizationSetting(): boolean {
  const availableOrganizationSettingsService = inject(AvailableOrganizationSettingsService);
  return availableOrganizationSettingsService.hasEnabledOrganizationSettings();
}
