import { EnvironmentProviders } from '@angular/core';

import { ApiKeysPermissions } from '@mp/kernel/api-keys/feature';
import { OrganizationsPermissions } from '@mp/kernel/organizations/feature';
import { provideModulePermissions } from '@mp/kernel/permissions/data-access';
import { IdentityUsersPermissions } from '@mp/kernel/users/feature';
import { provideModuleInfo } from '@mp/shared/util';

const moduleName = 'Kernel';

export function provideKernelFeature(): EnvironmentProviders[] {
  return [
    provideModuleInfo(moduleName, {
      displayName: 'System',
    }),
    provideModulePermissions(moduleName, IdentityUsersPermissions, OrganizationsPermissions, ApiKeysPermissions),
  ];
}
