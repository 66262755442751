import { Injectable, inject } from '@angular/core';

import { type RegisteredSettings } from '@mp/kernel/settings/data-access';

import { ORGANIZATION_SETTINGS_TOKEN } from '../injection-tokens';

@Injectable({ providedIn: 'root' })
export class AvailableOrganizationSettingsService {
  private readonly registeredSettings: readonly RegisteredSettings[] =
    inject(ORGANIZATION_SETTINGS_TOKEN, {
      optional: true,
    }) ?? [];

  public hasEnabledOrganizationSettings(): boolean {
    return this.registeredSettings.some(({ settings }) => settings.isEnabled?.() !== false);
  }
}
